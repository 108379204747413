import Head from "next/head";

const ItemsList = ({ data = [] }) => {
  let itemList = [
    // {
    //   "@type": "ListItem",
    //   position: 1,
    //   item: {
    //     name: "Home",
    //     "@url": `${process.env.NEXT_PUBLIC_WEBSITE_URL}`,
    //     "@id": `${process.env.NEXT_PUBLIC_WEBSITE_URL}`,
    //   },
    // },
  ];

  if (data.length > 0) {
    data?.map((item, index) => {
      itemList.push({
        "@type": "ListItem",
        position: index + 1,
        name: item?.title_short,
        "@url": item?.canonical_url?.includes(
            process.env.NEXT_PUBLIC_WEBSITE_URL
          )
            ? `${item?.canonical_url}`
            : process.env.NEXT_PUBLIC_WEBSITE_URL + `${item?.canonical_url}`,
      });
    });
  }

  return (
    <Head>
      <script
        id="ItemListSchema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            itemListElement: itemList,
          }),
        }}
      />
    </Head>
  );
};

export default ItemsList;
